.startbody {
  background-color: black;
  background-image: url('../assets/linebackground.jpg');
  height: 100vh;
  background-repeat: no-repeat;
  margin: 0 !important;
  background-position: right;
  background-size: cover;
}

.loadinggrid {
  display: flex !important;
  flex-wrap: nowrap !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  bottom: 10%;
  position: fixed;
  /* height: 100%; */
  /* margin-top: -35%; */
}

.logoContainer {
  display: flex;
  height: auto;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center !important;
  color: #F0B152;
}

.cavslogo {
  /* height: 30% !important; */
  width: 50%;
}

.cokelogo {
  height: 30% !important;
  width: auto;
}

.line {
  height: 30% ! important;
  width: auto;
  margin: 20px;
}


.image {
  width: 60%;
  max-width: 600px;
  height: auto;
  margin: 25px;
}

.title {
  font-family: 'Termina';
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 30px;
  color: #FBAE39;
  margin: 10px;
  text-align: center;
}

.subtitle{
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-size: .6rem;
  line-height: 13px;
  color: #fff;
  margin: 10px;
  text-align: center;
  width: 70%;
}



/* screen height of 667 */
@media only screen and (max-height: 667px) {
  .container{
   transform: scale(.9);
   bottom: 25px!important;
   position: fixed;
  }
}