.loadingbackground{
    background-color: black;
    background-image: url('../assets/linebackground.jpg');
    height: 100vh;
    background-repeat: no-repeat;
    margin: 0!important;
    background-position: right;
}

.loadinggrid{
    display: flex!important;
    flex-wrap: nowrap!important;
    justify-content: center!important;
    align-items: center!important;
    flex-direction: column!important;
}
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  
  .logoContainer {
    display: flex;
    justify-content: space-between;
    margin: 10px
    /* width: 60%; */
    /* max-width: 600px; */
  }
  
  .logo{
    width: auto;
    height: 35px;
    padding-left: 10px;
    padding-right: 10px;

  }

  .image {
    width: 70%;
    max-width: 600px;
    height: auto;
    /* margin: 25px; */
    padding: 25px;
  }
  
  .loadingBarContainer {
    width: 70%;
    max-width: 600px;
    border-radius: 25px;
    border: 2px solid #F0B152;
    overflow: hidden;
    display: flex;
    align-items: center!important;
  }
  
  .loadingBar {
    /* margin: 12px!important; */
    width: 20%;
    height: 100%;
    justify-content: center;
    background-color: transparent!important;
  }
  
  .loadingText{
    color: white;
    font-size: 12px;
    color: #B4975A;
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    opacity: 0.5;
    margin: 5px;
  }