.header{
    display: flex;
    margin: 0 auto;
    padding: 20px;
    
}

.image {

    margin: 0 auto;
    width: 40%;
    justify-content: center;
    align-items: center;

  }
