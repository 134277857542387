.shareContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-image: url('../assets/share-background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  position: absolute;
}


.textOverlay {
  position: absolute;
  top: 10px;
  left: -70px;
  color: white;
  font-size: 36px;
  text-align: center;
  transform: rotate(270deg);
  width: 100%;
  line-break: anywhere;
}


.logoOverlay img {
  position: absolute;
  top: 75%;
  right: 25px;
  width: 200px;

}

/* .brandingOverlay {
  aspect-ratio: 9/16;
}
.brandingOverlay img{
  width: 90%;
  position: absolute;
  top: 20px;
  left: 20px;
  transform-origin: center

} */

.playerImage {
  z-index: 0;

}

.banner {
  width: 100px;
}

.backButton {
  width: "100px";
  height: "25px";
  border: "0px solid black";
  background-color: "#FBAE39";
  color: "black";
  text-transform: "uppercase";
  font-weight: "bold";
  z-index: "100";
  border-radius: "10px";
  top: 0;
  right: 0;
  position: "absolute";
}